import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
@Injectable()
export class SharedService {
  private token = new Subject<string>();
  private acting = new Subject<string>();

  constructor() { }
  /*
   * @return {Observable<string>} : siblingMsg
   */
  public getToken(): Observable<string> {
    return this.token.asObservable();
  }
  public getActing(): Observable<string> {
    return this.acting.asObservable();
  }
  /*
   * @param {string} message : siblingMsg
   */
  public updateToken(token: string): void {
    this.token.next(token);
  }
  public updateActing(acting: string): void {
    this.acting.next(acting);
  }
}