// import { VersionInfo } from '../../versioninfo.js';

export const environment = {
    production: false,
    endpoint: "https://addulate-api.yatilabs.com:3000",
    androidEndpoint: 'http://api.test.adclse.com',
    widgetEndpoint: 'http://widgets.test.adclse.com',
    apiGateway: 'https://api2.addulate.com',
    mixpanel_token: '123abc-test',
    releaseInfo: "VersionInfo"
};
