import { Component, Input } from '@angular/core';
import { trigger, style, animate, transition, state } from '@angular/animations';

import { NGXLogger } from 'ngx-logger';

import { IImage } from '../models/image.model';
import { IPlaylistItem } from '../models/playlistitem.model';
import { ContentMediaComponent } from 'app/content-media/content-media.component';
import { AnalyticsService } from 'app/services/analytics.service';
import { ImageService } from 'app/services/image.service';
import { SharedService } from 'app/services/shared.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'app-image-media',
    templateUrl: './image-media.component.html',
    styleUrls: ['./image-media.component.css'],
    animations: [
        trigger('fade', [
            state('void', style({ opacity: 0 })),
            state('hidden', style({ opacity: 0 })),
            state('showing', style({ opacity: 1 })),
            transition('hidden <=> showing', [
                animate(1000)
            ]),
            transition('void <=> showing', [
                animate(1000)
            ]),
        ])
    ],
})
export class ImageMediaComponent extends ContentMediaComponent {
    private image: IImage;
    private _playlistItem: IPlaylistItem;

    @Input()
    verbose: boolean;
    src: string = ''
    alt: string = 'default image';
    elem: any;
    token: string
    public subscription: Subscription;
    protected queryParamSubscription: Subscription;
    protected paramSubscription: Subscription;
    acting: string;
    protected startTime: Date = new Date();
    protected id: string;
    log: string[] = ['0.000 starting...'];

    ngOnInit() {
        this.queryParamSubscription = this.route.queryParams.subscribe((params: Params) => {
            this.setQueryParams(params);
        });

        this.paramSubscription = this.route.params.subscribe((params: Params) => {
            this.setParams(params);
        });
    }  
    ngOnDestroy() {
        this.queryParamSubscription.unsubscribe();
        this.paramSubscription.unsubscribe();
    }

    setParams(params: Params) {
        if (!!params && !!params.id) {
            this.id = params.id;
            this.analytics.logScreenID(this.id);
        }
    }

    setQueryParams(params: Params) {
        if (!!params && !!params.v) {  // turn on verbosity
            this.verbose = params.v !== '0';
        }
        if (!!params && !!params.a) { // pass through acting account (used for previewing)
            this.acting = params.a;
        }
        if (!!params && !!params.t) { // pass through token (needed for previewing)
            this.token = params.t;
        }
        if (!!params && !!params.i) {
            this.analytics.setUniqueId(params.i);
        } else if (!!params && !!params.adid) {
            this.analytics.setUniqueId(params.adid);
        }
        this.analytics.appLaunch();
    }



    get title(): string {
        return this.image ? this.image.title : '';
    }

    set playlistItem(playlistItem: IPlaylistItem) {
       this.ngOnInit()

        if (this._playlistItem === playlistItem) { return; }
        if (playlistItem.itemType !== 'image') {
            this.logger.error('ImageMediaComponent: invalid playlistItem type ', playlistItem.itemType);
        }
        this._playlistItem = playlistItem;
        this.durationMSecs = playlistItem.durationSecs * 1000;
        this.imageService.getById(playlistItem.itemId, { token: this.token, acting: this.acting }).subscribe(
            (image: IImage) => {
                // this.log.push(this.elapsedTime() + ' data loaded');
                this.image = image;
                this.src = this.image.imageUrl;
                this.alt = this.image.title;
            },
            (error: any) => {
                // this.log.push(this.elapsedTime() + ' ' + error.message);
            },
            () => {

            }
        );
        
        // if (!!playlistItem.item) {
        //     this.image = <IImage>playlistItem.item;
        //     this.src = this.image.imageUrl;
        //     this.alt = this.image.title;
        // }
    }

    constructor(
        private analytics: AnalyticsService,
        private logger: NGXLogger,
        private imageService: ImageService,
        protected route: ActivatedRoute,
    ) {
        super();
    }

    async load(): Promise<boolean> {
        await super.play();
        return Promise.resolve(true);
    }

    async play(): Promise<boolean> {
        await super.play();
        this.playing.emit();
        return Promise.resolve(true);
    }

    pause(): void {
        super.pause();
        this.paused.emit();
    }

    complete(): void {
        super.complete();
        this.analytics.logImageShown(this._playlistItem);
    }

    reset(): void {
        super.reset();
    }

    onContentLoaded(): void {
        this.loaded.emit(1.0);
    }

    onComplete() {
        this.ended.emit();
    }

}
